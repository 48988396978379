
.navbarColor {
    background-color: #224b5e;
    color: #e6e6e6;
}

.navbarTextColor {
    color: #00b3b3;
}

.darkBlue {
    color: #31708E
}

.midBlue {
    color: #5085A5
}

.lightBlue {
    color: #8FC1E3
}

.cream {
    color: #F7F9FB
}

.darkGreen {
    color: #687864
}

.darkGreenBG {
    background-color: #687864
}

.midBlueBG {
    background-color: #5085A5
}

.creamBG {
    background-color: #F7F9FB
}

.skills {
  text-align: right;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #8FC1E3;
  box-sizing: border-box;
  border: 2px solid black;
}

.htmlcss {
    width: 90%;
}

.git {
    width: 80%;
}

.python {
    width: 75%;
}

.js {
    width: 65%;
}

.java {
    width: 60%;
}

.php {
    width: 30%;
}

#container {
  margin: 20px;
  width: 200px;
  height: 200px;
  position: relative;
}

.circleBG {
    r: 70;
    cx: 100;
    cy: 100;
    fill: transparent;
    stroke: lightgrey;
    stroke-width: 2rem;
    stroke-dasharray: 439.8;
    stroke-dashoffset: 0;
}

.circleThirty {
    r: 70;
    cx: 100;
    cy: 100;
    fill: transparent;
    stroke: #8FC1E3;
    stroke-width: 2rem;
    stroke-dasharray: 300;
    stroke-dashoffset: 160;
}

.circleFourty {
    r: 70;
    cx: 100;
    cy: 100;
    fill: transparent;
    stroke: #8FC1E3;
    stroke-width: 2rem;
    stroke-dasharray: 300;
    stroke-dashoffset: 120;
}

.circleFifty {
    r: 70;
    cx: 100;
    cy: 100;
    fill: transparent;
    stroke: #8FC1E3;
    stroke-width: 2rem;
    stroke-dasharray: 285;
    stroke-dashoffset: 66;
}

.circleSixty {
    r: 70;
    cx: 100;
    cy: 100;
    fill: transparent;
    stroke: #8FC1E3;
    stroke-width: 2rem;
    stroke-dasharray: 395;
    stroke-dashoffset: 120;
}

.circleSeventy {
    r: 70;
    cx: 100;
    cy: 100;
    fill: transparent;
    stroke: #8FC1E3;
    stroke-width: 2rem;
    stroke-dasharray: 395;
    stroke-dashoffset: 66;
}

.circleEighty {
    r: 70;
    cx: 100;
    cy: 100;
    fill: transparent;
    stroke: #8FC1E3;
    stroke-width: 2rem;
    stroke-dasharray: 395;
    stroke-dashoffset: 30;
}
