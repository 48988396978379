@import url("https://unpkg.com/@tailwindcss/typography@0.4.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

.cursive {
  font-family: "Noto Sans", cursive;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 100px;
}